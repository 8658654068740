import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay'
/*global $, Stripe*/

const getSecret = _ => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
const keesubsUrl = process.env.NODE_ENV === 'production' ? 'https://app.keesubscriptions.com/' : 'http://127.0.0.1:8000/';
const stripeKey = process.env.NODE_ENV === 'production' ? 'pk_live_fwJ074wbjA93bGNQvtHPdYMU' : 'pk_test_5tpbOidFaQLpSlxHMNOlSQw7';

class App extends Component {
  state = {
    orders: [],
    actions: [],
    loadingActions: false
  }

  componentDidMount() {
    this.setState({
      orders: this.props.orders,
      actions: this.props.actions||[]
    })

    if (this.props.openStripe) {
      setTimeout(_ => this.openPortal(), 1);
    }
  }

  update_keesubs = (rawValue, cb) => {
    // Allow single or multiple actions
    const value = Array.isArray(rawValue) ? rawValue : [rawValue];

    const actions = [...this.state.actions, ...value];
    this.setState({actions});

    // Load the edit_account page in an iframe
    var frame = $('<iframe src="/_myacct/edit_account" style="width:0;height:0;border:0; border:none;" />')
    frame.on('load', () => {
      // Add the KeeSubs data to a textarea in the form
      var textarea = $('<textarea name="' + this.props.cancelMisc + '"></textarea>');
      textarea.text(JSON.stringify(this.state.actions));

      var form = $('form[name=UPDATEINFO]', frame[0].contentDocument)
      form.append(textarea);

      // POST the form and remove ourselves
      $.post(form.attr('action'), form.serialize(), () => {
          frame.remove();
          cb && cb();
        }
      ).fail(function(err){ alert("We're sorry, but something went wrong cancelling your subscriptions! Please try again later or contact us to make sure your subscription is cancelled."); });
    });

    // GO!
    $('body').append(frame);
  }

  updateSubscriptionHandler = (subscription_id, action, args) => {
    const { groupByName, stripe } = this.props;

    //find the item being cancelled
    const updatedOrder = this.state.orders.find(subscription => subscription.id === subscription_id);

    // Find all affected orders
    const updatedOrders = (groupByName ? this.state.orders.filter(_ => _.name === updatedOrder.name) : [updatedOrder]);
    const updatedSubscriptionIds = updatedOrders.map(_ => _.id);

    // Update the interface
    this.setState({
      orders: this.state.orders.map(subscription => updatedSubscriptionIds.includes(subscription.id) ?
      { ...subscription, action }
        :
      subscription
      )
    });

    // Send request to directly to Keesubs server
    if (stripe) { // Backward compat..
      if (action === 'cancel') {
        this.doSubscriptionAction(subscription_id, action, 'cancelled');
        return;
      } else if (action === 'pause') {
        this.doSubscriptionAction(subscription_id, action, 'paused', args);
        return;
      } else if (action === 'unpause') {
        this.doSubscriptionAction(subscription_id, action, 'active');
        return;
      }

    }

    this.update_keesubs(updatedOrders.map(_ => ({ id: _.id, action })), _ => {

      // Update the interface to show that we're posted
      this.setState({
        orders: this.state.orders.map(subscription => updatedSubscriptionIds.includes(subscription.id) ? {
          ...subscription,
          posted: true
        } : subscription)
      });

    });
  }

  doSubscriptionAction(subscription_id, action, status_after, args) {
    return this.doAction(action, async ({url}) => {
        try {
          const { secret } = await $.post(url, { subscription_id, ...args });
          //
          this.setState({
            orders: this.state.orders.map(subscription => subscription.id === subscription_id ? {
              ...subscription,
              status: status_after
            } : subscription),
            actions: this.state.actions.map(_ => {
              if (_['action'] === 'auth') {
                _['secret'] = secret;
              }
              return _;
            })
          });

        } catch {
          alert("We're sorry, but something went wrong updating your subscriptions! Please try again later or contact us to make sure your subscription is cancelled.");

        }

      });
  }

  doAction(action, cb) {
    const { username, siteId } = this.props;

    const doCbWithSecret = secret => cb({ secret, url: keesubsUrl + 'actions/' + siteId + '/' + username + '/' + secret + '/' + action }) && this.setState({ loadingActions: false });

    // Check if we have a secret already
    const actions = this.state.actions.filter(_ => _['action'] === 'auth');
    try {
      const cachedSecret = actions[0]['secret'];
      return doCbWithSecret(cachedSecret);

    } catch {}

    this.setState({ loadingActions: true });
    // Generate a secret and send to server
    const secret = getSecret();

    this.update_keesubs({
      'action': 'auth',
      'secret': secret
    }, _ => {
      doCbWithSecret(secret);
    });
  }

  updatePaymentMethod = _ => {
    const { stripe } = this.props;

    this.doAction('update-cc', ({url}) => {
      // Site ID, username, secret
      $.get(url).then(res => {

        const _stripe = Stripe(stripeKey, { stripeAccount: stripe });

        _stripe.redirectToCheckout({
            sessionId: res.id

        }).then(function (result) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
        });
      });
    })

  }

  openPortal = _ => {
    this.doAction('portal', ({url}) => {
      window.location = url;
    });

  }

  render() {
    const { username, siteId, bootstrapVersion, groupByName, readOnly, labels, enablePortal } = this.props;
    const { loadingActions } = this.state;
    const isBs4 = bootstrapVersion === '4';

    const {
      title = 'My Subscriptions',
      manageSubscriptions = 'Manage Subscriptions',
      status = 'Status',
      datePlaced = 'Date Placed',
      renewsOn = 'Renews On',
      endsOn = 'Ends On',
      frequency = 'Frequency',
      cancel = 'Cancel Subscription',
      pause = 'Pause Subscription',
      unpause = 'Unpause Subscription',
      reactivate = 'Reactivate',
    } = labels||{};

    let openPortalLink = <></>;

    if (username && siteId && enablePortal ) {
      const openPortalClassnames = isBs4 ? 'badge badge-secondary' : 'badge badge-secondary'; // TODO: bs3

      openPortalLink = <>
        <a href="?keesubscriptions=1" className={openPortalClassnames} onClick={e => e.preventDefault() || this.openPortal()}>{manageSubscriptions}</a>
      </>;
    }

    const orders = this.state.orders.sort((a, b) =>
      // Show subscriptions that renew the soonest first
      a.current_period_end > b.current_period_end).reduce((ret, item) =>

      // If we're grouping by name, filter out duplicates
      groupByName && ret.map(_ => _.name).includes(item.name) ? ret : [...ret, item], []
    );

    const getPauseIntervals = (subscription) => {
      const intervalSeconds = subscription.interval_count * {
        day: 86400,
        week: 604800,
        month: 2592000,
      }[subscription.interval];

      const oneYearSeconds = 31536000;

      return Array.from({ length: 3 }, (_, index) => subscription.current_period_end + (index + 1) * intervalSeconds).filter(interval => interval - subscription.current_period_end < oneYearSeconds);

    }


    return (
      <>
        <div>
          <hr />
          {this.props.bootstrapVersion === '4' ? <h2>{title} {openPortalLink}</h2> : <h3>{title} {openPortalLink}</h3>}
          <div className="row">
            {orders.map(order => (
              <article className="col-12 col-lg-4 col-md-6 mb-3" key={order.id}>
                <div className="card card-body well">
                  <h4 className="mb-0 font-weight-bold">
                    {order.name}
                  </h4>
                  <hr />
                  <p>{status}: <strong><span style={{ fontSize: '100%', textTransform: 'capitalize' }} className={`badge label ${order.status === 'active' || order.status === 'trialing' ? ' badge-success label-success' : ' badge-warning label-warning'}`}>
                    {(order.status === 'active' || order.status === 'trialing') ? 'Active' : order.status}</span></strong></p>
                  <p>{datePlaced}: <strong>{order.dateOrdered}</strong></p>
                  <p>{frequency}: <strong>Every {order.interval_count > 1 ? order.interval_count + ' ' + order.interval + 's' : order.interval} </strong></p>

                  <p>{order.cancel_at_period_end ? <span>{endsOn}</span> : <span>{renewsOn}</span>}: <strong>{order.renews}</strong></p>

                  {((order.action === this.props.action_cancel && !order.posted && this.props.bootstrapVersion === '4') || (order.action === this.props.action_reactivate && !order.posted && this.props.bootstrapVersion === '4')) &&
                    <div className="spinner-grow text-info mx-auto" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>}
                  {((order.action === this.props.action_cancel && !order.posted && this.props.bootstrapVersion !== '4') || (order.action === this.props.action_reactivate && !order.posted && this.props.bootstrapVersion !== '4')) &&
                    <h4 className="text-danger" style={{ paddingTop: '5px' }}>Processing...</h4>}
                  <div className="text-center" style={{ marginTop: '10px' }}>

                    {(order.action === this.props.action_cancel && order.posted && this.props.bootstrapVersion === '4') && <h4 className="text-info">Cancellation Request Sent</h4>}
                    {(order.action === this.props.action_cancel && order.posted && this.props.bootstrapVersion !== '4') && <h4 style={{ paddingTop: '5px' }} className="text-info">Cancellation Request Sent</h4>}

                    {(order.action === this.props.action_reactivate && order.posted && this.props.bootstrapVersion === '4') && <h4 className="text-info">Reactivation Request Sent</h4>}
                    {(order.action === this.props.action_reactivate && order.posted && this.props.bootstrapVersion !== '4') && <h4 style={{ paddingTop: '5px' }} className="text-info">Reactivation Request Sent</h4>}

                    {(!readOnly && order.action !== this.props.action_cancel && !order.posted && !order.cancel_at_period_end ) && <div className="row">

                      {
                        order.status === 'paused' ? <>
                          <div className="col-12 col-xs-12">
                            <button
                              className="btn btn-outline-primary btn-block"
                              value={order.id}
                              onClick={() => this.updateSubscriptionHandler(order.id, 'unpause')}>
                                {unpause}
                            </button>
                          </div>
                        </> :
                        <>
                        <div className="col-6 col-xs-6">
                          <button
                            className="btn btn-outline-secondary btn-block"
                            value={order.id}
                            onClick={() => { if (window.confirm(`Are you sure you want to cancel your subscription?`)) { this.updateSubscriptionHandler(order.id, this.props.action_cancel) }; }}>
                              {cancel}
                          </button>
                          </div>
                          <div className="col-6 col-xs-6">

                            <button type="button" className="btn btn-outline-primary btn-block dropdown-toggle" data-toggle="dropdown">
                              {pause}
                            </button>
                            <div class="dropdown-menu">
                              {getPauseIntervals(order).map(interval => <>
                                <button
                                  class="dropdown-item"
                                  onClick={() => this.updateSubscriptionHandler(order.id, 'pause', {
                                    pause_until: interval
                                  })}
                                  >Until {new Date(interval * 1000).toLocaleDateString("en-AU",{dateStyle:"long"})}</button>
                              </>)}
                            </div>
                          </div>
                        </>
                      }


                    </div>}

                    {(!readOnly && order.action !== this.props.action_reactivate && !order.posted && order.cancel_at_period_end) && <button className="btn btn-outline-secondary btn-block" value={order.id} onClick={() => this.updateSubscriptionHandler(order.id, this.props.action_reactivate)}>{reactivate}</button>}

                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
        { loadingActions && <LoadingOverlay
            styles={{ wrapper: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 9999,
              background: 'rgba(0, 0, 0, 0.3)'
            }}}
            active
            spinner
            text='Subscription portal is loading...'
            />
        }
      </>
    );
  }
}

export default App;
