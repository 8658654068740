import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
/*global $, window*/

// Check if this is a hardlink to the self-service portal
const qs = name => {
  const url = window.location.href;

  name = name.replace(/[[]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);

  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

(function() {
  const openStripe = !!qs('keesubscriptions');

  //find the version of bootstrap
  const bootstrapVersion = $.fn.tooltip.Constructor.VERSION||'3';

  //get all the possible actions
  const action_reactivate = 'reactivate';
  const action_cancel = 'cancel';

  // get all keesubscriptions from the customer's past orders from [@customer_ref4@]
  const ref4Items = document.querySelectorAll('.keesubs-subscription');

  const subscriptions = [];
  let orderSubscriptions;

  for (let i = 0; i < ref4Items.length; i++)
  {
      let el = ref4Items.item(i);
      try{
        orderSubscriptions = JSON.parse(el.innerHTML);
        for (let j=0; j < orderSubscriptions.length; j++)
        {
          orderSubscriptions[j]['orderId'] = el.dataset['orderId'];
          orderSubscriptions[j]['datePlaced'] = el.dataset['datePlaced'];
          subscriptions.push(orderSubscriptions[j]);
        }
      }catch{
        orderSubscriptions = false;
      }
  }

  const container = document.getElementById('keesubportal');
  if ( !container ) {
    return;
  }
  const config = Object.assign({}, container.dataset);

  // BACKWARD COMPAT - 2020-09-05
  if ( document.querySelector('meta[name="keesubs-cancel-misc"]') ) {

    config.misc = document.querySelector('meta[name="keesubs-cancel-misc"]').content;
    config.actions = document.querySelector('meta[name="keesubs-cancel"]').content;

  }

  try{ config.actions = JSON.parse(config.actions) }
  catch { config.actions = []; }

  try{ config.labels = JSON.parse(config.labels) }
  catch { config.labels = {}; }

  //get items from orderCustomField and see if any items have are in the queue to get canceled if not, then give action = "cancel"
  try {
    config.actions.forEach((item) => {
      subscriptions.forEach((sub) => {
        if(sub.id !== item.id || sub.status === 'canceled'){
          return
        }
        if([action_cancel,action_reactivate].indexOf(item.action) !== -1){
          sub.action = item.action;
          sub.posted = true;
        }
      });
    });
  }catch{
    config.actions = [];
  }

  const activeSubscriptions = [];
  subscriptions.forEach((order) => {
    if(order.status!=="canceled"){
      order.dateOrdered = new Date(order.datePlaced).toLocaleDateString("en-AU",{dateStyle:"long"});
      order.renews = new Date(order.current_period_end * 1000).toLocaleDateString("en-AU",{dateStyle:"long"});
      activeSubscriptions.push(order);
      if(order.cancel_at_period_end === true){
        order.status = "Active (non-renewing)";
      }
    }
  });

  if(activeSubscriptions.length > 0){
    container.classList.add('col-xs-12');
    container.classList.add('col-12');
    ReactDOM.render(<App
                      bootstrapVersion = {bootstrapVersion.charAt(0)}
                      orders = {activeSubscriptions}
                      action_cancel ={action_cancel}
                      action_reactivate ={action_reactivate}
                      cancelMisc = {config.misc}
                      actions = {config.actions||[]}
                      username={config.username}
                      siteId={config.siteId}
                      stripe={config.stripe}
                      groupByName={typeof config.groupByName !== 'undefined'}
                      readOnly={typeof config.readOnly !== 'undefined'}
                      openStripe={openStripe}
                      enablePortal={typeof config.disablePortal === 'undefined' || (!config.disablePortal && config.disablePortal !== "")}
                      labels={config.labels}
                      />, container);
  }

})()